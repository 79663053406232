
export default {
  props: {
    layout: {
      type: String,
      required: false,
      default: 'default-style',
    },
    data: {
      type: Object,
      default: () => ({
        AboutUs: {},
        Broker: {},
        Decoder: {},
        Lifestage: {},
      }),
    },
  },

  data() {
    return {
      useWhiteStyle: false,
      whiteStyleLock: false,
      isScrollToTop: true,

      isNavigationContentOn: false,
      navigationContentType: null,

      isMobileNavMenuOn: false,
      locale: this.$i18n.locale
    };
  },

  created() {
    this.$nuxt.$on('leaveNavigationContent', () => {
      this.leaveNavigationContent();
      this.isMobileNavMenuOn = false;
    });
  },
  mounted() {
    this.$nextTick(() => {
        // console.log('route', this.$route.path)
        let prevScrollpos = window.scrollY;
        window.addEventListener('scroll', () => {
          const pattern = /^[.\/\-:\/a-zA-Z\d]*\/products\/(.)*/mg;
          const scSitePattern = /hong-kong-insurance/i;
          if (pattern.test(this.$route.path) || scSitePattern.test(this.$route.path)) {

            if (!this.isMobileNavMenuOn) {
              const currentScrollPos = window.scrollY;
              const element = document.getElementsByClassName("nav-bar")[0]
              const mobileNavBar = document.getElementsByClassName("nav-bar-mobile-wrapper")[0]
              // console.log(mobileNavBar)

              if (currentScrollPos <= 399) {
                element.style.top = "0";
                element.style.transition = "top 0.3s"

                mobileNavBar.style.top = "0"
              } else if (currentScrollPos >= 400) {
                element.style.top = "-100px";
                element.style.transition = "top 0.3s";

                if(window.innerWidth <= 1024) {
                  mobileNavBar.style.top = "-100px"
                  mobileNavBar.style.transition = "top 0.3s"

                }
              }
              prevScrollpos = currentScrollPos;
            }
          }
        })
    })
  },
  beforeDestroy() {
    // console.log('destory')
    window.onscroll = () => {};
  },
  // for debugging purpose only!
  // mounted() {
  //   this.enterNavigationContent();
  //   this.turnOnNavigationContent('aboutus');
  // },

  methods: {
    toggleWhiteStyle(value, isScrollToTop = null, mouseleave = false) {
      if (isScrollToTop != null) this.isScrollToTop = isScrollToTop;
      if (mouseleave && !this.isScrollToTop) return;

      this.useWhiteStyle = value;
    },

    turnOnWhiteStyle() {
      if (this.whiteStyleLock) {
        setTimeout(() => this.toggleWhiteStyle(true), 150);
      } else {
        this.toggleWhiteStyle(true);
      }
    },

    turnOffWhiteStyle() {
      this.toggleWhiteStyle(false, null, true);
    },

    turnOnNavigationContent(navigationContentType) {
      this.isNavigationContentOn = true;
      if (navigationContentType) this.navigationContentType = navigationContentType;
    },

    turnOffNavigationContent() {
      this.isNavigationContentOn = false;
      this.navigationContentType = null;
    },

    enterNavigationContent(navigationContentType) {
      this.turnOnWhiteStyle();
      this.turnOnNavigationContent(navigationContentType);
    },

    leaveNavigationContent() {
      this.turnOffNavigationContent();
      this.whiteStyleLock = true;
      setTimeout(() => {
        this.turnOffWhiteStyle();
        this.whiteStyleLock = false;
      }, 150);
    },

    toggleMobileNavMenu() {
      this.isMobileNavMenuOn = !this.isMobileNavMenuOn;

      // hack for triggering scroll event to fire the global scroll event handler
      // for adjusting the white style according to scrollTop value
      window.scrollTo(window.scrollX, window.scrollY - 1);
      window.scrollTo(window.scrollX, window.scrollY + 1);
    },

    resetBlogFilter() {
      this.$store.commit('blog/clearTagCodes');
      this.$store.commit('blog/clearAuthorCodes');
    },
  },
  computed: {
    isHideFunctionBlock() {
      if (this.locale == 'zh-CN') {
        return true;
      }
      return false;
    },
    drifterZoneCNEntryPoints () {
      let entryPoints = [];
      if (this.data?.DrifterZoneCNEntryPoints) {
        for (let item of this.data?.DrifterZoneCNEntryPoints.Header) {
          entryPoints.push(item);
        }
      }
      return entryPoints;
    },
    landingPath() {
      return this.locale == 'zh-CN'? this.localePath('/zh-CN') : this.locale == 'zh-HK' ? this.localePath('/zh-HK') : this.localePath('/');
    }
  }
};
