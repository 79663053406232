import globalCookies from "./globalCookies";
import middlePlatformTrackingMixin from "./middlePlatformTrackingMixin";
export default {
  mixins: [globalCookies,middlePlatformTrackingMixin],
  mounted() {
    //
  },
  methods: {
    async getLGMConversionId(params) {
      return await this.getLGMTrackingHash(params);
    },
    injectLGMConversionId(transitionCheckoutLink, conversionId) {
      if (transitionCheckoutLink.includes('transition-checkout')) {

        const url = new URL(transitionCheckoutLink);
        const cid = conversionId;
        let QUERY_STRING_10LIFECID = '10lifecid';
        let QUERY_STRING_10LIFECID_VALUE = `${cid}`;
        
        if (transitionCheckoutLink.includes('insurer=AXA')) {
          QUERY_STRING_10LIFECID = 'utm_content';
          QUERY_STRING_10LIFECID_VALUE = `10lifecid_${cid}`;
        }

        const linkParam = url.searchParams.get("link");
        if (linkParam) {
          const linkUrl = new URL(decodeURIComponent(linkParam));
          linkUrl.searchParams.set(QUERY_STRING_10LIFECID, QUERY_STRING_10LIFECID_VALUE);
          url.searchParams.set("link", (linkUrl.toString()));
        }
        return url;
        
      } else {
        return transitionCheckoutLink;
      } 
    },
    injectLGMConversionIdToEComSite(eComSiteLink, conversionId) {
      if (eComSiteLink.includes('/purchase')) {

        const url = new URL(eComSiteLink);
        const cid = conversionId;
        const QUERY_STRING_10LIFECID = '10lifecid';
        const QUERY_STRING_10LIFECID_VALUE = `${cid}`;

        url.searchParams.set(QUERY_STRING_10LIFECID, QUERY_STRING_10LIFECID_VALUE);
        return url;
      } else {
        return eComSiteLink;
      } 
    },

    /**
     * 
     * @param {*} clickedEvent 
     * @param {*} aTagClassSelectorString 
     * @param {*} eventTrackingParams 
     * @param {*} destinationType  - transitionLink or eCommerceSite
     * @returns url
     */
    async getLGMTrackingUrl(clickedEvent, aTagClassSelectorString='a', eventTrackingParams, destinationType = 'transitionLink') {
      const injectionFn = {
        "transitionLink": this.injectLGMConversionId,
        "eCommerceSite": this.injectLGMConversionIdToEComSite
      }
      const currentUrl = window.location.href;
      let url;
      try {
        url = clickedEvent?.target?.closest(aTagClassSelectorString).href;
        const lgmParams = {
          "epg": currentUrl,
          "dpg": url,
          "lgmMeta": eventTrackingParams.c ? eventTrackingParams.c : undefined,
        }
        const cid = await this.getLGMConversionId(lgmParams);
        url = injectionFn[destinationType](url, cid);
      } catch (error) {
        console.error('Error:', error);
      }
      return url;
    },

    openWindowWithLGMUrl(clickedEvent, aTagClassSelectorString='a', eventTrackingParams, destinationType = 'transitionLink') {
      const windowReference = window.open();
      this.getLGMTrackingUrl(clickedEvent, aTagClassSelectorString, eventTrackingParams, destinationType).then((url) => {
        windowReference.location = url;
      });
    }
  },
}